// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  localDevelopment: false,
  production: 'PRODUCTION_BOOL',
  oauth: {
    client_id: 'boston',
    client_secret: 'boston'
  },
  // Redirect false When using dev for GWIM and testing directly luma-app with its login modal
  redirectOnAuthenticationError: 'REDIRECT_ON_ERROR',
  environmentName: 'dev',
  showEnvBanner: 'SHOW_ENV_BANNER',
  hosts: {
    api_gateway: 'https://scg.bdev.lumafintech.com',
    go: 'https://go.bdev.lumafintech.com',
    navian: 'https://dev-www.lumafintech.com',
    portal: 'https://ngportal.lumafintech.com',
    zuul_gateway: 'https://bdev.lumafintech.com',
    pdf_gateway: 'https://pdf.bdev.lumafintech.com'
  },
  datadog_enabled: 'DATADOG_ENABLED',
  datadog_sample_rate: '100',
  datadog_replay_sample_rate: '100',
  datadog_application_id: 'ef9208e1-2a4d-479a-a1d5-4dbe92ecd1b3',
  datadog_client_token: 'pubf900d210f3bffce6fe9bc3b933de07df',
  datadog_service_name: 'dev-luma-app',
  circleci_deployment_version: 'luma-app@update-datadog-config-f1bb4b7',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
