import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  AnnuityMapperService,
  AnnuityRate,
  AnnuityResponse
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-mapper.service';
import { UserService } from '@common/services/user.service';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import {
  AnnuityIllustrationParams,
  AnnuityIllustrationSummaryModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-illustration-summary/annuity-illustration-summary.model';
import { Utilities } from '@common/utilities/utilities';
import {
  buildDiaSpiaParamsObject,
  DiaSpiaRequestModel,
  DiaSpiaResponseModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-configure/dia-spia-configure/dia-spia-request.model';
import * as moment from 'moment';
import {
  FirelightAccessToken,
  FirelightActivityRequestModel,
  FirelightActivityResponseModel,
  FL_ACORD_TYPES_CODES
} from '@product-marketplace/annuity-product/annuity-product-view/firelight/firelight-models/firelight-activity-request.model';
import {
  AnnuityIndicesModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-indices.model';
import {
  AnnuityChartDataRequestModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-configure/annuity-chart-data-request.model';
import {
  AnnuityOrderRequestModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-order-request.model';
import { LifecycleAnnuityProduct } from '@common/models/annuity-product';
import { AnnuityCalendarEvent } from '@common/models/annuity-calendar-event';
import { ANNUITY_PRODUCT_TYPES, ANNUITY_USER_TYPES } from '@product-marketplace/annuity-product/annuity-constants';
import {
  AnnuitySavedProductConfigModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-saved-product-config.model';
import { AnnuitySalesRep, AnnuityStateService } from '@product-marketplace/annuity-product/annuity-state.service';
import {
  MappedExchangeScenario
} from '@product-marketplace/annuity-product/annuity-product-view/exchange-1035/annuity-exchange.models';
import { USER_AUTHORITIES } from '@common/models/user';
import {
  AnnuityUserInputModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-user-input.model';
import { AnnuityUserInformation } from '@common/resolvers/annuity-user-info.resolver';
import {
  AnnuityOesRequestModel
} from '@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-oes-request.model';
import {
  OesUploadDocumentResponse
} from "@product-marketplace/annuity-product/annuity-product-view/annuity-models/annuity-oes-upload-response";


@Injectable({
  providedIn: 'root'
})
export class AnnuityService {
  private readonly ANNUITY_PATH = `api/annuity-service`;
  private readonly ANNUITY_ROOT = `${environment.hosts.api_gateway}/${this.ANNUITY_PATH}`;
  private readonly ANNUITY_SERVICE = `${this.ANNUITY_ROOT}/annuity`;
  private readonly ANNUITY_PRODUCT_URL = `${this.ANNUITY_SERVICE}/products`;
  // private readonly ANNUITY_PRODUCT_VIEW_URL = `${this.ANNUITY_SERVICE}/productviews`;
  private readonly FILTERED_PRODUCT_VIEW_URL = `${this.ANNUITY_SERVICE}/filteredproductviews`;
  private readonly ASSIGNED_PRODUCT_VIEWS = `${this.ANNUITY_ROOT}/assignedproductviews`; // uses root
  private readonly ANNUITY_COMPANIES_URL = `${this.ANNUITY_SERVICE}/companies`;
  private readonly ANNUITY_COMPANY_TYPES_URL = `${this.ANNUITY_SERVICE}/companytypes`;
  private readonly ANNUITY_STATES_URL = `${this.ANNUITY_SERVICE}/states`;
  // private readonly ANNUITY_PRODUCT_TYPES_URL = `${this.ANNUITY_SERVICE}/types`;
  private readonly ANNUITY_COMPANY_PRODUCT_TYPES_URL = `${this.ANNUITY_SERVICE}/companyproducttypes`;
  private readonly ANNUITY_USER_PRODUCT_TYPES_URL = `${this.ANNUITY_SERVICE}/userproducttypes`;
  private readonly ANNUITY_ILLUSTRATION_PARAMS = `${this.ANNUITY_SERVICE}/illustrations/config`;
  private readonly ANNUITY_ILLUSTRATION_URL = `${this.ANNUITY_SERVICE}/illustrations`;
  private readonly ANNUITY_DIA_SPIA_URL = `${this.ANNUITY_SERVICE}/diaspia`;
  private readonly ANNUITY_DIA_SPIA_ILLUSTRATION_URL = `${this.ANNUITY_DIA_SPIA_URL}/illustration`;
  private readonly ANNUITY_ORDER_HISTORY = `${this.ANNUITY_SERVICE}/orderhistory`;
  private readonly ANNUITY_PRODUCT_CONFIG_HISTORY = `${this.ANNUITY_SERVICE}/productconfig`;
  private readonly ANNUITY_FIRELIGHT_URL = `${this.ANNUITY_ROOT}/oes/fl`; // env/api/annuity-service/oes/fl
  private readonly ANNUITY_FIRELIGHT_SSO = `${this.ANNUITY_SERVICE}/oes/fl/sso`; // TODO?
  private readonly ANNUITY_INDICES_URL = `${this.ANNUITY_SERVICE}/indices`;
  private readonly ANNUITY_INIT_ORDER_EVENT = `${this.ANNUITY_SERVICE}/orderevents`;
  private readonly ANNUITY_OES_VENDORS = `${this.ANNUITY_SERVICE}/oesvendors`;
  private readonly ANNUITY_CARRIER_VENDORS = `${this.ANNUITY_SERVICE}/carriervendors`;
  private readonly ANNUITY_FIRELIGHT_CLONE = `${this.ANNUITY_FIRELIGHT_URL}/activities/clone`;
  private readonly ANNUITY_FIRELIGHT_CLONE_NEW_PRODUCT = `${this.ANNUITY_FIRELIGHT_URL}/activities/clonenew`;
  private readonly ANNUITY_FIRELIGHT_EXISTING_TOKEN_BY_REF_ID = `${this.ANNUITY_FIRELIGHT_URL}/ordertoken`;
  private readonly ANNUITY_FIRELIGHT_STATUS = `${this.ANNUITY_FIRELIGHT_URL}/activities/status`;
  private readonly ANNUITY_OUTLIER_INDICES = `${this.ANNUITY_SERVICE}/outliersindices`;
  private readonly ANNUITY_PRODUCT_PROFILE = `${this.ANNUITY_SERVICE}/prodprofile/generate`;
  private readonly ANNUITY_VA_PRODUCT_PROFILE = `${this.ANNUITY_SERVICE}/va/prodprofile`;
  private readonly ANNUITY_IMO_BROKERS = `${this.ANNUITY_SERVICE}/imos/brokerdealers`;
  private readonly ANNUITY_BROKER_DEALER_CONFIG = `${this.ANNUITY_SERVICE}/brokerdealerconfig`;
  private readonly ANNUITY_EBIX_BUY = `${this.ANNUITY_SERVICE}/oes/ebix/buy`;
  private readonly ANNUITY_FIRELIGHT_NATIVE_BUY_LOWER = `https://uat.firelighteapp.com/EGApp/SSOGateway/TokenGateway`;
  private readonly ANNUITY_FIRELIGHT_NATIVE_BUY_PROD = `https://www.firelighteapp.com/EGApp/SSOGateway/TokenGateway`;
  private readonly ANNUITY_FIG_BUY = `${this.ANNUITY_SERVICE}/oes/fig`;
  private readonly ANNUITY_INCOME_COMPARISON = `${this.ANNUITY_SERVICE}/products/incomecomparison`;
  private readonly ANNUITY_INCOME_COMPARISON_EXPORT = `${this.ANNUITY_SERVICE}/prodprofile/incomecomparison`;
  // Ebix document upload endpoints
  private readonly ANNUITY_OES_SIDE_COMPARISON_EXPORT = `${this.ANNUITY_SERVICE}/oesupload/comparison/fiarila`;
  private readonly ANNUITY_OES_RATES_VIEW_EXPORT = `${this.ANNUITY_SERVICE}/oesupload/ratesviewexport`;
  private readonly ANNUITY_OES_INCOME_COMPARISON_EXPORT = `${this.ANNUITY_SERVICE}/oesupload/incomecomparison`;

  private readonly ANNUITY_CARRIERS_COPY_DIFFERENT_DISABLED = `${this.ANNUITY_ROOT}/oes/fl/copydisablelist`;

  constructor(private http: HttpClient,
              private userService: UserService,
              private annuityMapperService: AnnuityMapperService,
              private annuityStateService: AnnuityStateService) {
  }

  private productUrlFromVendorUniqueId(vendorUniqueId: string) {
    return `${this.ANNUITY_PRODUCT_URL}/${vendorUniqueId}`;
  }

  private buildFrontierUrl(vendorUniqueId): string {
    return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/frontier`;
  }

  private buildAnalysisUrl(vendorUniqueId): string {
    return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/stackedanalysis`;
  }

  getFilteredProducts(state, age, brokerDealer, isAdmin, productTypes): Observable<AnnuityResponse> {
    // Functionality supports multiple Broker Dealers for Carriers - params/URL are different based on user type
    const url = isAdmin ? this.ASSIGNED_PRODUCT_VIEWS : this.FILTERED_PRODUCT_VIEW_URL;
    const brokerDealerParam = isAdmin ? 'bd' : 'bds';

    let params = new HttpParams()
      .set('state', state)
      .set('age', age);

    if (brokerDealer?.id) {
      params = params.append(brokerDealerParam, brokerDealer.shortname);
    }

    productTypes.forEach(productType => {
      params = params.append('pt', productType);
    });


    return this.getAnnuityUserInformation().pipe(switchMap(annuityUserInformation => {
      return this.http.get(url, {
        params
      }).pipe(map((annuityResponse: any) => {
        return this.annuityMapperService.mapAnnuityResponse(annuityResponse, annuityUserInformation);
      }));
    }));
  }

  getAnnuityUserInformation(): Observable<AnnuityUserInformation> {
    const requests = [
      this.getButtonPermissions(),
      this.getBrokerDealerOes(),
    ];

    return forkJoin(requests).pipe(map(([annuityBtnPermissions, oes]) => {
      return {
        annuityBtnPermissions,
        oes
      };
    }));
  }

  getButtonPermissions(): Observable<{ buyBtnPermission: boolean; configureBtnPermission: boolean }> {
    return of(
      {
        configureBtnPermission: this.userService.getUserPermissions().includes(WidgetPermissions.MenuMarketplaceAnnuityConfigureButton),
        buyBtnPermission: this.userService.getUserPermissions().includes(WidgetPermissions.MenuMarketplaceAnnuityBuyButton)
      }
    );
  }

  getAnnuityProductFrontierChartInformation(vendorUniqueId: string, productName): Observable<any> {
    const annuityFrontierChartUrl = this.buildFrontierUrl(vendorUniqueId);
    return this.http.get(annuityFrontierChartUrl).pipe(map((data: any) => {
        const strategiesMap = new Map(Object.entries(data.strategies));
        const formattedEfficientFrontierTooltip = this.annuityMapperService.formatFrontierData(data, productName);
        return {
          frontier: {
            strategiesMap,
            data: formattedEfficientFrontierTooltip
          }
        };
      }
    ), catchError((() => {
      return of({frontier: null});
    })));
  }

  getAnnuityProductChartInformation(annuityChartDataRequestModel: AnnuityChartDataRequestModel, maxAtznAge: number, growthChartColors: string[]): Observable<any> {
    const requests: Observable<any>[] = [];
    if (annuityChartDataRequestModel.needIncome || annuityChartDataRequestModel.needGrowth) {
      requests.push(this.getAnalysisData(annuityChartDataRequestModel, maxAtznAge, growthChartColors,));
    }

    if (annuityChartDataRequestModel.needFrontier) {
      requests.push(this.getAnnuityProductFrontierChartInformation(annuityChartDataRequestModel.vendorUniqueId, annuityChartDataRequestModel.productName));
    }
    return forkJoin(requests).pipe(map((chartData) => {
      return chartData.reduce((previous, currentChart) => {
        return {
          ...previous,
          ...currentChart
        };
      });
    }), catchError(() => {
      return of({
        growth: null,
        income: null,
        message: '',
        frontier: null
      });
    }));
  }

  getAnalysisData(annuityChartDataRequestModel: AnnuityChartDataRequestModel, maxAtznAge, growthChartColors): Observable<any> {
    const analysisUrl = this.buildAnalysisUrl(annuityChartDataRequestModel.vendorUniqueId);
    return this.http.get(analysisUrl, {params: annuityChartDataRequestModel.buildGrowthHttpParams()}).pipe(map((analysis: any) => {
      const mapIncomeToLineChart = (data: any[]) => {
        return !!data
          ? {
            series: [{
              data: data.filter(dt => dt.age < maxAtznAge).map(dt => {
                return {
                  x: dt.age,
                  y: dt.accountValue,
                  label: dt.formattedAge,
                };
              })
            }]
          }
          : null;
      };

      const growth = {
        series: analysis.growthResult.map((strategy, strategyIndex) => {
          const index = annuityChartDataRequestModel.getIndexFromStrategy(strategy.strategyRateId);
          const formattedIndex = this.annuityMapperService.formatAnnuityRateFromColumn((index.annuityRate as AnnuityRate), annuityChartDataRequestModel.columns);
          return {
            name: index ? (index.annuityRate as AnnuityRate)?.underlieIndex : strategy.strategyRateId,
            data: strategy.growthData.map(dt => {
              return {
                x: dt.age,
                y: dt.accountValue,
                formattedIndex,
                label: dt.formattedAge,
              };
            }),
            color: growthChartColors[strategyIndex % growthChartColors.length]
          };
        })
      };
      return {
        growth,
        income: mapIncomeToLineChart(analysis?.incomeResult),
        message: analysis?.message
      };
    }));
  }


  // not used anywhere today, but maybe are needed somewhere or at some point
  // private buildIncomeChartUrl(vendorUniqueId): string {
  //   return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/income`;
  // }
  //
  // private buildGrowthChartUrl(vendorUniqueId): string {
  //   return `${this.productUrlFromVendorUniqueId(vendorUniqueId)}/growth`;
  // }
  // getProductGrowthChart(vendorUniqueId: string, httpParams: HttpParams): Observable<any> {
  //   const annuityGrowthChartUrl = this.buildGrowthChartUrl(vendorUniqueId);
  //   return this.http.get(annuityGrowthChartUrl, {params: httpParams});
  // }
  //
  // getProductIncomeChart(vendorUniqueId: string, httpParams: HttpParams, startingAge): Observable<any> {
  //   httpParams = httpParams.set('startingAge', startingAge);
  //   const annuityIncomeChartUrl = this.buildIncomeChartUrl(vendorUniqueId);
  //   return this.http.get(annuityIncomeChartUrl, {params: httpParams});
  // }

  getFirelightBaseUrl(): Observable<string> {
    return this.http
      .get(`${this.ANNUITY_FIRELIGHT_URL}/baseurl`, {responseType: 'text'});
  }


  getFireLightTokenByReferenceId(referenceId: string): Observable<FirelightAccessToken> {
    let params = new HttpParams().set('orderevent', referenceId);
    return this.http.post<FirelightAccessToken>(`${this.ANNUITY_FIRELIGHT_EXISTING_TOKEN_BY_REF_ID}`, null, {params});
  }

  getFireLightToken(brokerDealerName: string = '', repSelected?: string): Observable<FirelightAccessToken> {
    let params = new HttpParams();
    if (repSelected) {
      params = params.set('username', repSelected);
    }
    if (brokerDealerName) {
      return this.http.post<FirelightAccessToken>(`${this.ANNUITY_FIRELIGHT_URL}/accesstoken`, brokerDealerName, {params});
    }
    return this.http.get<FirelightAccessToken>(`${this.ANNUITY_FIRELIGHT_URL}/accesstoken`);
  }


  invalidActivityIdResponse(response: FirelightActivityResponseModel) {
    if (!response) return true;
    const regex = /^[-0]+$/;
    return regex.test(response.ActivityId)
  }

  getFireLightTokenAndNewActivity(requestModel: FirelightActivityRequestModel,
                                  type: 'create' | 'copy' | 'continue' = 'create',
                                  referenceId: string = null,
                                  repSelected?: string): Observable<FirelightActivityResponseModel> {
    switch (type) {
      case "create":
        return this.createFirelightActivity(
          requestModel,
          "",
          repSelected
        ).pipe(tap(res => {
          if (this.invalidActivityIdResponse(res)) {
            throw new Error('Invalid Activity Id');
          }
        }))
      case "copy":
        return this.cloneFirelightActivity(requestModel, referenceId).pipe(
          tap((res) => {
            if (this.invalidActivityIdResponse(res)) {
              throw new Error('Invalid Activity Id');
            } else {
              this.annuityStateService.annuityProductCopyInfo = null;
            }
          }),
        );
      case "continue":
        if (referenceId) {
          return this.getFireLightTokenByReferenceId(referenceId).pipe(
            map((tokenData: FirelightAccessToken) => {
              return {AccessToken: {...tokenData}} as FirelightActivityResponseModel;
            }));
        } else {
          return this.getFireLightToken(
            requestModel.oesBrokerDealerName,
            repSelected
          ).pipe(
            map((tokenData: FirelightAccessToken) => {
              return {AccessToken: {...tokenData}} as FirelightActivityResponseModel;
            })
          );
        }
      default:
        // type is required and typescript should yell if it's not one of the above types
        return;
    }
  }

  createFirelightActivity(firelightActivityRequestModel: FirelightActivityRequestModel, token: string = '', repSelected?: string): Observable<FirelightActivityResponseModel> {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (token) {
      headers = headers.set('firelighttoken', token);
    }
    if (repSelected) {
      params = params.set('username', repSelected);
    }
    return this.http.post<FirelightActivityResponseModel>(`${this.ANNUITY_FIRELIGHT_URL}/activities`, firelightActivityRequestModel, {
      headers,
      params
    });
  }

  openFirelightNative(annuityOesRequestModel: AnnuityOesRequestModel, repSelected?: AnnuitySalesRep, customActivityName?: string | null) {
    const oesVendorName = annuityOesRequestModel?.annuityProduct?.oesVendorName;
    const oesVendorParam = annuityOesRequestModel?.annuityProduct?.oesVendorParam;
    const carrier = annuityOesRequestModel?.annuityProduct?.carrier;
    // todo: check if this is accurate, see embedded component constructor. may need to handle 'from illustrations' as well.
    const firelightActivityRequestModel = FirelightActivityRequestModel.fromOesRequestModel(annuityOesRequestModel);
    firelightActivityRequestModel.acordTypeCode = FL_ACORD_TYPES_CODES.APPLICATION;
    return this.getCarrierInfoFromVendor(oesVendorName, carrier).pipe(
      switchMap(carrierInfo => {
        firelightActivityRequestModel.carrierCode = carrierInfo[carrier];
        firelightActivityRequestModel.setActivityNameWithCarrier(carrier, customActivityName); // keep here because of timestamp
        firelightActivityRequestModel.productType = annuityOesRequestModel.annuityProduct.productType === ANNUITY_PRODUCT_TYPES.DIA_SPIA ? ANNUITY_PRODUCT_TYPES.DIA_SPIA : null;
        const actionType = annuityOesRequestModel.reference
          ? annuityOesRequestModel.triggerClone
            ? 'copy'
            : 'continue'
          : 'create';
        return this.getFireLightTokenAndNewActivity(firelightActivityRequestModel, actionType, annuityOesRequestModel.reference, repSelected?.userName).pipe(
          switchMap((resp: FirelightActivityResponseModel) => {
            const annuityOrderRequestModel = this.createInitialOrderEventPayload({
              annuityOesRequestModel,
              repSelected,
              annuityFirelightResponse: resp,
            })
            this.createInitialOrderEvent(annuityOrderRequestModel).subscribe();
            return this.getFirelightNativeBuy(resp.AccessToken.access_token, (resp.ActivityId ?? annuityOesRequestModel.reference), oesVendorParam)
          }));
      }));
  }


  getAnnuityPoint(vendorUniqueId: string, allocations: Array<string>, productName: string): Observable<any> {
    const frontierAllocationURL = `${this.buildFrontierUrl(vendorUniqueId)}/allocation`;
    let httpParams = new HttpParams();
    allocations.forEach(allocation => {
      httpParams = httpParams.append('allocs', allocation);
    });

    return this.http.get(frontierAllocationURL, {params: httpParams}).pipe(
      map((response: any) => {
        const formattedVal = this.annuityMapperService.formatEfficientFrontierTooltip(response, productName);
        // TODO - may be temp
        const usePoint = !!(formattedVal && formattedVal.saKey && formattedVal.y && formattedVal.x && formattedVal.displayVal);
        return {
          usePoint,
          point: formattedVal
        };
      }), catchError(() => {
        return of({
          usePoint: false,
          point: null
        });
      }));
  }

  getAnnuityUserCompanies(): Observable<{ carriers: AnnuityCompany[], brokerDealers: AnnuityCompany[] }> {
    return this.http.get<AnnuityCompany[]>(this.ANNUITY_COMPANIES_URL).pipe(
      map((companies) => {
        const carriers = companies.filter(company => {
          return company.role === ANNUITY_USER_TYPES.CARRIER;
        });

        const brokerDealers = companies.filter(company => {
          return company.role === ANNUITY_USER_TYPES.BROKER_DEALER;
        });

        return {
          carriers,
          brokerDealers
        };
      }),
      catchError(() => {
        return of(null);
      }));
  }

  getCompanyTypes(): Observable<string> {
    return this.http.get(this.ANNUITY_COMPANY_TYPES_URL, {responseType: 'text'})
      .pipe(catchError(() => {
        return of('');
      }));
  }

  getBrokerDealerOes(): Observable<any> {
    return this.http.get(this.ANNUITY_OES_VENDORS).pipe(
      catchError(() => {
        return of({});
      })
    );
  }

  // not used anywhere
  // getBrokerDealerOesForProduct(uniqueVendorId: string): Observable<any> {
  //   return this.http.get(`${this.ANNUITY_OES_VENDORS}/${uniqueVendorId}`).pipe(
  //     catchError(err => {
  //       return of({});
  //     })
  //   );
  // }

  getCarriersFromVendor(vendor: string): Observable<any> {
    return this.http.get(`${this.ANNUITY_CARRIER_VENDORS}/${vendor}`);
  }

  getCarrierInfoFromVendor(vendor: string, carrier: string): Observable<any> {
    const vendorToUse = vendor === 'FIG_OES' ? 'FireLight' : vendor;
    return this.http.get(`${this.ANNUITY_CARRIER_VENDORS}/${vendorToUse}/${carrier}`);
  }

  getAnnuityStates(): Observable<string[]> {
    if (this.annuityStateService.annuityStates) {
      return of(this.annuityStateService.annuityStates);
    } else {
      return this.http.get<string[]>(this.ANNUITY_STATES_URL).pipe(
        tap({
          next: (states) => {
            this.annuityStateService.annuityStates = states;
          }
        }),
        catchError(() => {
          return of([]);
        }));
    }

  }

  // not used anywhere
  // getAnnuityProductTypes(): Observable<string[]> {
  //   return this.http.get<string[]>(this.ANNUITY_PRODUCT_TYPES_URL).pipe(catchError(() => {
  //     return of([]);
  //   }));
  // }

  getAnnuityUserProductTypes(isAdminOrCarrier): Observable<string[]> {
    if (this.annuityStateService.annuityUsersProductTypes) {
      return of(this.annuityStateService.annuityUsersProductTypes);
    } else {
      const url = isAdminOrCarrier ? this.ANNUITY_COMPANY_PRODUCT_TYPES_URL : this.ANNUITY_USER_PRODUCT_TYPES_URL;
      return this.http.get<string[]>(url).pipe(
        tap({
          next: (productTypes) => {
            this.annuityStateService.annuityUsersProductTypes = productTypes;
          }
        }));
    }
  }

  getBatchAnnuityIllustrationParams(vendorUniqueIds: string[]): Observable<AnnuityIllustrationParams[]> {
    const batchRequests = vendorUniqueIds.map(vendorUniqueId => this.getAnnuityIllustrationParams(vendorUniqueId));

    return forkJoin(batchRequests);
  }

  getAnnuityIllustrationParams(vendorUniqueId): Observable<AnnuityIllustrationParams> {
    // Check if params are cached locally
    if (this.annuityStateService.illustrationParamsMap.has(vendorUniqueId)) {
      return of(this.annuityStateService.illustrationParamsMap.get(vendorUniqueId));
    }

    const params = new HttpParams()
      .set('cusip', vendorUniqueId);

    return this.http.get<AnnuityIllustrationParams>(this.ANNUITY_ILLUSTRATION_PARAMS, {
      params
    }).pipe(map((annuityIllustrationParams: AnnuityIllustrationParams) => {
      const annuityIllustrationParamsWithId: AnnuityIllustrationParams = {
        ...annuityIllustrationParams,
        vendorUniqueId
      };

      this.annuityStateService.illustrationParamsMap.set(vendorUniqueId, annuityIllustrationParamsWithId);

      return annuityIllustrationParamsWithId;
    }), catchError(() => of(null)));
  }

  getAnnuityIllustration(summary: AnnuityIllustrationSummaryModel): Observable<any> {
    let params = new HttpParams()
      .set('cusip', summary.cusip)
      .set('agentName', summary.agentName)
      .set('stateCode', summary.state || 'OH')
      .set('clientName', summary.name)
      .set('clientAge', summary.age || 55)
      .set('clientSex', summary.sex || 'Male')
      .set('premium', Utilities.stripCurrencyFormatting(summary.initialPremium) || '100000')
      .set('type', summary.type)
      .set('vendor', summary.annuityParams?.vendor);

    if (summary.incomeStartAge) {
      params = params.set('incomeStartAge', summary.incomeStartAge);
    }

    if (summary.incomeRiderCode) {
      params = params.set('incomeRiderCode', summary.incomeRiderCode);
    }


    summary.allocations.map(allocation => allocation.data.rateKey + ',' + allocation.allocation).forEach(alloc => {
      params = params.append('alocs', alloc);
    });

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/pdf')
      .set('Accept', 'application/pdf');

    return this.http.get(this.ANNUITY_ILLUSTRATION_URL,
      {headers: httpHeaders, params, responseType: 'blob'})
      .pipe(map(data => {
        return data;
      }), catchError(() => {
        return of(null); // TODO
      }));
  }


  getDiaSpiaGridData(diaSpiaRequest: DiaSpiaRequestModel, oesVendorName: string): Observable<DiaSpiaResponseModel[]> {
    const getCarriers = this.getCarriersFromVendor(oesVendorName);
    const getDiaSpiaOptions = this.getDiaSpiaOptions(diaSpiaRequest);
    return forkJoin([getCarriers, getDiaSpiaOptions]).pipe(map(([carriers, diaSpiaProducts]) => {
      const mappedCarriers = carriers ? Object.keys(carriers).map((key) => {
        return {
          shortName: key,
          carrierCode: carriers[key]
        };
      }) : [];
      diaSpiaProducts.forEach((product) => {
        product.lumaCarrier = mappedCarriers.find(carrier => product.carrier.includes(carrier.shortName));
        product.productType = ANNUITY_PRODUCT_TYPES.DIA_SPIA;
      });
      return diaSpiaProducts;
    }));
  }


  getDiaSpiaOptions(diaSpiaRequest: DiaSpiaRequestModel): Observable<DiaSpiaResponseModel[]> {
    return this.http.get(this.ANNUITY_DIA_SPIA_URL, {
      params: buildDiaSpiaParamsObject(diaSpiaRequest)
    }).pipe(map((optionsResponse: any) => {
      optionsResponse?.forEach((option) => {
        option.income = option.income ? Utilities.formatCurrency(option.income) : '-';
        option.premium = option.premium ? Utilities.formatCurrency(option.premium) : '-';
        option.taxablePortion = option.taxablePortion ? Utilities.formatCurrency(option.taxablePortion) : '-';
        if (option.guaranteedEnd != null) {
          const d = moment(new Date(option.guaranteedEnd));
          option.guaranteedEnd = d.isValid() ? d.format('MMMM DD, YYYY') : option.guaranteedEnd;
        }
      });
      return optionsResponse;
    }), catchError((err) => {
      const errMsg = typeof err === 'string' ? err : 'There was an error processing this request';
      return throwError(() => errMsg);
    }));
  }

  getDiaSpiaIllustration(illustrationId, brokerDealer): Observable<Blob> {
    const illustrationUrl = `${this.ANNUITY_DIA_SPIA_ILLUSTRATION_URL}/${illustrationId}`;
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/pdf')
      .set('Accept', 'application/pdf');
    let httpParams = new HttpParams();
    if (brokerDealer) {
      httpParams = httpParams.set('bd', brokerDealer);
    }
    return this.http.get(illustrationUrl, {params: httpParams, headers: httpHeaders, responseType: 'blob'})
      .pipe(catchError(() => {
        return of(null);
      }));
  }

  // TODO - Fix // not used anywhere
  // private createMockedData = (observer) => {
  //   setTimeout(() => {
  //     const generatedTestData = [];
  //     for (let i = 1; i <= 100; i++) {
  //       const obj = {};
  //       const pad = Array(Math.max(3 - String(i).length + 1)).join('0') + i;
  //       Object.keys(obj).forEach((key) => {
  //         if (typeof obj[key] === 'string') {
  //           obj[key] = `${obj[key]} ${pad}`;
  //         }
  //       });
  //
  //       generatedTestData.push(obj);
  //     }
  //     observer.next(generatedTestData);
  //   }, 1200);
  //
  //   return {
  //     unsubscribe() {
  //     }
  //   };
  // }

  getAnnuityOrderHistory(brokerDealer, input): Observable<any> {
    let params = new HttpParams();
    if (brokerDealer) {
      params = params.append('bd', brokerDealer.shortname);
    }

    if (input?.isoStart && input?.isoEnd) {
      params = params.append('startDate', input.isoStart);
      params = params.append('endDate', input.isoEnd);
    }

    if (this.userService.getAuthorities().includes(USER_AUTHORITIES.SALES_ASSISTANT)) {
      params = params.append('isSA', true);
    }

    return this.http.get(this.ANNUITY_ORDER_HISTORY, {params}).pipe(
      map((data: any[]) => this.annuityMapperService.mapAnnuityOrderEventData(data)),
      catchError(() => {
        return of(null);
      })
    );
  }

  getAnnuityIndices(): Observable<AnnuityIndicesModel[]> {
    return this.http.get<AnnuityIndicesModel[]>(this.ANNUITY_INDICES_URL).pipe(
      map((annuityIndices: AnnuityIndicesModel[]) => {
        return this.annuityMapperService.mapAnnuityIndices(annuityIndices);
      }),
      catchError(() => of([]))
    );
  }


  createInitialOrderEventPayload(
    config: {
      annuityOesRequestModel: AnnuityOesRequestModel,
      repSelected?: AnnuitySalesRep
      annuityFirelightResponse?: FirelightActivityResponseModel
    }) {

    const {annuityOesRequestModel, repSelected, annuityFirelightResponse} = config;
    const annuityOrderRequestModel = new AnnuityOrderRequestModel();
    annuityOrderRequestModel.reference = annuityFirelightResponse?.ActivityId ?? annuityOesRequestModel.reference;
    // Annuity Product
    annuityOrderRequestModel.oesVendor = annuityOesRequestModel?.annuityProduct?.oesVendorName;
    annuityOrderRequestModel.oesVendorParam = annuityOesRequestModel?.annuityProduct?.oesVendorParam;
    annuityOrderRequestModel.oesBrokerDealer = annuityFirelightResponse?.OesBdName ?? annuityOesRequestModel?.annuityUserInput?.oesBrokerDealerName;
    annuityOrderRequestModel.cusip = annuityOesRequestModel?.annuityProduct?.cusip;
    annuityOrderRequestModel.activityName = annuityOesRequestModel?.activityName;
    if (annuityOesRequestModel?.annuityProduct?.productType === ANNUITY_PRODUCT_TYPES.DIA_SPIA
      || !annuityOesRequestModel?.annuityProduct?.vendorUniqueId) {
      annuityOrderRequestModel.productName = annuityOesRequestModel?.annuityProduct?.productName;
      annuityOrderRequestModel.carrier = annuityOesRequestModel?.annuityProduct?.carrier;
      annuityOrderRequestModel.annuityType = annuityOesRequestModel?.annuityProduct?.productType;
    } else {
      annuityOrderRequestModel.vendorUniqueId = annuityOesRequestModel?.annuityProduct?.vendorUniqueId;
    }
    // Annuity User Input
    annuityOrderRequestModel.clientState = annuityOesRequestModel?.annuityUserInput?.state;
    annuityOrderRequestModel.premium = annuityOesRequestModel?.annuityUserInput?.premium;
    annuityOrderRequestModel.clientAge = annuityOesRequestModel?.annuityUserInput?.age;
    // Other Inputs
    annuityOrderRequestModel.transactionTime = new Date().toISOString();
    annuityOrderRequestModel.status = 0;
    annuityOrderRequestModel.eventType = 0;
    annuityOrderRequestModel.financialAdviser =
      // todo: this is a response from our endpoint, it should have financialadviser prop.
      // responseFromFirelight?.FinancialAdviser ||
      annuityOesRequestModel.financialAdviserId ||
      (repSelected ? repSelected?.uuid : this.userService.getUuid());
    annuityOrderRequestModel.financialAdviserName = annuityFirelightResponse?.FinancialAdviserName ||
      repSelected?.fullName;
    annuityOrderRequestModel.brokerDealerShortName = annuityOesRequestModel.brokerDealerShortName || this.annuityStateService.brokerDealerShortName;
    return annuityOrderRequestModel;
  }

  createInitialOrderEvent(body: AnnuityOrderRequestModel): Observable<any> {
    return this.http.post(this.ANNUITY_INIT_ORDER_EVENT, body);
  }


  //  not used
  // getLifecycleProducts(bd: string): Observable<{ lifeCycleProducts: LifecycleAnnuityProduct[], demoFlag: boolean }> {
  //   let params = new HttpParams();
  //   if (bd) {
  //     params = params.set('bd', bd);
  //   }
  //   return this.http.get<{
  //     lifeCycleProducts: LifecycleAnnuityProduct[],
  //     demoFlag: boolean
  //   }>(this.ANNUITY_SERVICE + '/lifecycleproducts', {params});
  // }


  exportLifecycleProducts(request, maxExportSize, brokerDealerShortName?: string | null) {
    let params = new HttpParams();
    if (brokerDealerShortName) {
      params = params.append('bd', brokerDealerShortName);
    }
    if (request.sortModel?.length > 0) {
      params = params.append('direction', request.sortModel[0].sort);
      params = params.append('sort', request.sortModel[0].colId);
    }
    if (request.search) {
      params = params.append('search', request.search);
    }
    params = params.append('maxExportSize', maxExportSize.toString());
    const requestBody = {};
    for (const [key, value] of Object.entries(request.filters)) {
      if (request.filters.hasOwnProperty(key)) {
        if ((value as string[])?.length !== 0) {
          requestBody[key] = value;
        }
      }
    }
    return this.http.post(this.ANNUITY_SERVICE + '/lifecycleproductspaged/export',
      requestBody,
      {params, responseType: 'blob'});
  }

  getLifecycleProductsPaged(pageRequest, bd?: string | null,): Observable<{
    lifeCycleProducts: LifecycleAnnuityProduct[]
  }> {
    let params = new HttpParams();
    if (bd) {
      params = params.append('bd', bd);
    }
    params = params.append('page', pageRequest.page);
    params = params.append('pageSize', pageRequest.pageSize);
    if (pageRequest.sortModel?.length > 0) {
      params = params.append('direction', pageRequest.sortModel[0].sort);
      params = params.append('sort', pageRequest.sortModel[0].colId);
    }
    if (pageRequest.search) {
      params = params.append('search', pageRequest.search);
    }
    const requestBody = {};
    for (const [key, value] of Object.entries(pageRequest.filters)) {
      if (pageRequest.filters.hasOwnProperty(key)) {
        if ((value as string[])?.length !== 0) {
          requestBody[key] = value;
        }
      }
    }
    return this.http.post<{ lifeCycleProducts: LifecycleAnnuityProduct[], demoFlag: boolean }>
    (this.ANNUITY_SERVICE + '/lifecycleproductspaged', requestBody, {params});
  }

  disableOrderEvent(reference: string): Observable<any> {
    const params = new HttpParams()
      .set('reference', reference);
    return this.http.post(this.ANNUITY_INIT_ORDER_EVENT + '/disable', null, {params});
  }


  getLifecycleEvents(startDate: Date, endDate: Date, bd: string): Observable<{
    calendarEvents: AnnuityCalendarEvent[],
    eventTypes: string[],
    demoFlag: boolean
  }> {
    let params = new HttpParams()
      .set('startdate', Utilities.getISODateWithoutTime(startDate))
      .set('enddate', Utilities.getISODateWithoutTime(endDate));

    if (bd) {
      params = params.set('bd', bd);
    }

    return this.http.get<{
      calendarEvents: AnnuityCalendarEvent[],
      eventTypes: string[],
      demoFlag: boolean
    }>(this.ANNUITY_SERVICE + '/lifecyclevents', {params});
  }

  getLifecycleData(policyNumber: string): Observable<any> {
    const params = new HttpParams()
      .set('policy', policyNumber);

    return this.http.get<any>(this.ANNUITY_SERVICE + '/lifecycledata', {params});
  }

  firelightSingleSignOn(activity, oesBrokerDealerName): Observable<any> {
    let params = new HttpParams().set('activity', activity);

    if (oesBrokerDealerName) {
      params = params.set('bd', oesBrokerDealerName);
    }

    return this.http.get(this.ANNUITY_FIRELIGHT_SSO, {params});
  }

  cloneFirelightActivity(firelightActivityRequestModel: FirelightActivityRequestModel, referenceId: string): Observable<any> {
    const {vendorUniqueId, ...rest} = firelightActivityRequestModel;
    const payload = {
      ...rest,
      existingActivityId: referenceId,
      id: vendorUniqueId,
    }
    return this.http.post(this.ANNUITY_FIRELIGHT_CLONE_NEW_PRODUCT, payload);
  }

  cloneActivity(requestBody, brokerDealerName: string): Observable<FirelightActivityResponseModel> {
    return this.getFireLightToken(brokerDealerName).pipe(switchMap(token => {
      const headers = new HttpHeaders().set('firelighttoken', token.access_token);
      return this.http.post<FirelightActivityResponseModel>(this.ANNUITY_FIRELIGHT_CLONE, requestBody, {headers})
        .pipe(
          tap((res) => {
            if (this.invalidActivityIdResponse(res)) {
              throw new Error('Invalid Activity Id');
            }
          }),
          map(resp => {
            return {
              ...resp,
              // token doesn't get added to response on clone, use the access token we got before cloning
              AccessToken: {
                ...token,
              }
            }
          }));
    }));
  }

  getLatestAnnuityOrderStatus(reference: string, brokerDealerName: string): Observable<any> {
    return this.getFireLightToken(brokerDealerName).pipe(switchMap(token => {
      const params = new HttpParams()
        .set('activityId', reference);
      const headers = new HttpHeaders().set('firelighttoken', token.access_token);
      return this.http.get(this.ANNUITY_FIRELIGHT_STATUS, {params, headers}).pipe(
        map((data: any[]) => this.annuityMapperService.mapAnnuityOrderEventData(data)));
    }));
  }


  getEmptyIndices(): Observable<string[]> {
    return this.http.get<string[]>(this.ANNUITY_OUTLIER_INDICES).pipe(catchError(() => {
      return of([]);
    }));
  }


  // product would have 'activeState' and 'age' for income comparison
  generateProductProfile(isVa: boolean, vendorUniqueId: string, product?: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/pdf')
      .set('Accept', 'application/pdf');
    let params = new HttpParams().set('vuid', vendorUniqueId);
    params = params.set('state', product?.activeState ?? this.annuityStateService.state?.getValue());
    if (this.annuityStateService.selectedBrokerDealer?.getValue()) {
      params = params.set('bd', this.annuityStateService.brokerDealerShortName);
    }
    const ageParam = product?.age ?? this.annuityStateService.age?.getValue();
    if (ageParam) {
      params = params.set('age', ageParam);
    }

    if (isVa) {
      return this.http.get(this.ANNUITY_VA_PRODUCT_PROFILE, {params, headers, responseType: 'blob'});
    } else {
      return this.http.get(this.ANNUITY_PRODUCT_PROFILE, {params, headers, responseType: 'blob'});
    }
  }

  retrieveProductConfiguration(brokerDealer): Observable<any> {
    let params = new HttpParams();
    if (brokerDealer) {
      params = params.set('desk', brokerDealer.shortname);
    }

    return this.getAnnuityUserInformation().pipe(switchMap(annuityUserInformation => {
      return this.http.get(this.ANNUITY_PRODUCT_CONFIG_HISTORY, {
        params
      }).pipe(map((annuityResponse: any) => {
          return annuityResponse.map(response => {
            return {
              ...this.annuityMapperService.mapSavedProductConfig(response),
              ...annuityUserInformation.annuityBtnPermissions,
              oes: annuityUserInformation.oes
            };
          });
        }), catchError(() => {
          return of([]);
        })
      );
    }));
  }

  saveProductConfiguration(annuitySummaryModel: AnnuitySavedProductConfigModel) {
    const savedConfig = {
      type: annuitySummaryModel.type === 'Joint' ? 'J' : 'S',
      pcn: annuitySummaryModel.productConfigurationName,
      vui: annuitySummaryModel.vui,
      name: annuitySummaryModel.productName,
      carr: annuitySummaryModel.carrier,
      prm: parseInt(annuitySummaryModel.premium.replace(/[^0-9.]+/g, ''), 10),
      isd: annuitySummaryModel.indexStartDate != null ? Utilities.getISODateWithoutTime(annuitySummaryModel.indexStartDate) : null,
      ied: annuitySummaryModel.indexEndDate != null ? Utilities.getISODateWithoutTime(annuitySummaryModel.indexEndDate) : null,
      g: annuitySummaryModel.gender.charAt(0),
      st: annuitySummaryModel.state,
      pa: annuitySummaryModel.applicantPurchaseAge,
      ral: annuitySummaryModel.rateAllocations,
      isa: annuitySummaryModel.incomeStartAge,
      dbrid: annuitySummaryModel.dbRiderId,
      irid: annuitySummaryModel.incomeRiderId,
      gj: annuitySummaryModel.jointApplicantGender?.charAt(0),
      paj: annuitySummaryModel.jointApplicantPurchaseAge,
      isaj: annuitySummaryModel.jointIncomeStartAge,
      id: annuitySummaryModel.id,
      irn: annuitySummaryModel?.incomeRiderName,
      dbrn: annuitySummaryModel?.dbRiderName,
      at: annuitySummaryModel?.accountType,
      cn: annuitySummaryModel?.clientNeed,
      sy: annuitySummaryModel?.surrenderYear,
      cusip: annuitySummaryModel.cusip
    };

    if (annuitySummaryModel.id) {
      return this.http.put(this.ANNUITY_PRODUCT_CONFIG_HISTORY, savedConfig, {responseType: 'text'});
    } else {
      return this.http.post(this.ANNUITY_PRODUCT_CONFIG_HISTORY, savedConfig, {responseType: 'text'});
    }
  }

  getFilteredProductSingleView(vendorUniqueId, state, age, bdShortname?): Observable<any> {
    let params = new HttpParams()
      .set('state', state).set('age', age);
    if (bdShortname) {
      params = params.set('bd', bdShortname);
    }

    return this.http.get(this.ANNUITY_SERVICE + '/filteredproductviews/' + vendorUniqueId, {params});
  }

  analyticalProductComparison(requestBody): Observable<any> {
    return this.http.post(`${this.ANNUITY_PRODUCT_URL}/analytical`, requestBody)
      .pipe(catchError(() => {
        return of([]);
      }));
  }

  // not used anywhere
  // exportProductComparison(requestBody): Observable<any> {
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json');
  //   return this.http.post(`${this.ANNUITY_SERVICE}/prodprofile/comparison`, requestBody, {
  //     headers,
  //     responseType: 'blob'
  //   });
  // }

  exportProductRates(requestBody): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(`${this.ANNUITY_SERVICE}/prodprofile/ratesviewexport`, requestBody, {
      headers,
      responseType: 'blob'
    });
  }

  exportProductComparisonFiaRila(requestBody): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(`${this.ANNUITY_SERVICE}/prodprofile/comparison/fiarila`, requestBody, {
      headers,
      responseType: 'blob'
    });
  }

  exportOesProductComparisonFiaRila(requestBody): Observable<OesUploadDocumentResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<OesUploadDocumentResponse>(`${this.ANNUITY_OES_SIDE_COMPARISON_EXPORT}`, requestBody, {
      headers,
    });
  }

  exportOesProductRates(requestBody): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.post(this.ANNUITY_OES_RATES_VIEW_EXPORT, requestBody, {
      headers,
    });
  }

  exportOesIncomeComparison(requestBody): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.ANNUITY_OES_INCOME_COMPARISON_EXPORT, requestBody, {
      headers,
    });
  }

  exportVaProductComparison(vuids: string[], bd?: string): Observable<any> {
    let params = new HttpParams();
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');


    vuids.forEach(vuid => {
      params = params.append('vuids', vuid);
    });

    if (bd) {
      params = params.set('bd', bd);
    }
    return this.http.get(`${this.ANNUITY_SERVICE}/va/prodprofile/comparison`, {
      headers,
      params,
      responseType: 'blob'
    });
  }

  get1035AnalysisData(request: any, exchangeInformation: any): Observable<MappedExchangeScenario> {
    return this.http.post<any>(`${environment.hosts.api_gateway}/api/annuity-service/annuity/exchange`, request)
      .pipe(map(response => {
        return this.annuityMapperService.mapExchangeData(response.results, exchangeInformation);
      }));
  }

  // not used anywhere
  // get1035AnalysisData_DUMMY(): Observable<any> {
  //   return this.http.post<any>(`${environment.hosts.api_gateway}/api/annuity-exchange-service/annuityexchange/1035ex`, '')
  //     .pipe(map(response => {
  //       const mockedResponse = response?.results.map((result, index) => {
  //         const randomSummary = () => {
  //           const summary = {
  //             dbPaymentEv: Math.floor(Math.random() * (55555 - 2000) + 2000),
  //             incomeEv: Math.floor(Math.random() * (55555 - 2000) + 2000),
  //             feesTotalEv: Math.floor(Math.random() * (55555 - 2000) + 2000),
  //             incomeAnnualEv: Math.floor(Math.random() * (55555 - 2000) + 2000)
  //           } as ExchangeSummary;
  //           summary.totalEv = summary.incomeEv + summary.dbPaymentEv;
  //           return summary;
  //         };
  //         if (result.scenario === 'Zero') {
  //           result.scenario = 'Minimum';
  //         }
  //         return {
  //           exchangeInSummary: randomSummary(),
  //           exchangeOutSummary: randomSummary(),
  //           ...result as ExchangeScenario,
  //         };
  //       });
  //
  //       return this.annuityMapperService.mapExchangeData(mockedResponse, {
  //         carrierOut: 'Mock Carrier Out',
  //         carrierIn: 'Mock Carrier In'
  //       });
  //     }));
  // }

  export1035Analysis(requestBody): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(`${this.ANNUITY_SERVICE}/exchangeanalysis/export`, requestBody, {
      headers,
      responseType: 'blob'
    });
  }

  getBlackListedFeatures(): Observable<string[]> {
    if (this.annuityStateService.annuityBlacklistedFeatures) {
      return of(this.annuityStateService.annuityBlacklistedFeatures);
    }

    return this.http.get<string[]>(`${this.ANNUITY_SERVICE}/featureblacklist`).pipe(map(blacklistedFeatures => {
      this.annuityStateService.annuityBlacklistedFeatures = blacklistedFeatures;
      return blacklistedFeatures;
    }), catchError(() => {
      return of(null);
    }));
  }

  getUsersForSalesAssistant(username: string): Observable<any[]> {
    const params = new HttpParams().set('salesAssistant', username);
    return this.http.get<any[]>(this.ANNUITY_SERVICE + '/salesAssistant', {params});
  }

  getImoBrokers(): Observable<string[]> {
    return this.http.get<string[]>(this.ANNUITY_IMO_BROKERS);
  }


  getAnnuityBrokerDealerConfig(): Observable<AnnuityBrokerDealerConfig> {
    return this.http.get<AnnuityBrokerDealerConfig>(this.ANNUITY_BROKER_DEALER_CONFIG).pipe(catchError(() => {
      return of(
        null
      );
    }));
  }

  getEbixBuy(vendorUniqueId: string, annuityUserInput: AnnuityUserInputModel): Observable<EbixBuyResponse> {
    const params = new HttpParams().set('vendorUniqueId', vendorUniqueId);
    return this.http.post<EbixBuyResponse>(this.ANNUITY_EBIX_BUY, annuityUserInput, {params});
  }

  getFirelightNativeBuy(accessToken: string, activityId: string, oesParameter: string | null | undefined): Observable<boolean> {
    const formPost = document.createElement('form');
    const url = environment.environmentName?.toLowerCase() === 'prod' ? this.ANNUITY_FIRELIGHT_NATIVE_BUY_PROD : this.ANNUITY_FIRELIGHT_NATIVE_BUY_LOWER;

    var branding = '';
    if (oesParameter && JSON.parse(oesParameter).nativeOesBranding) {
      branding = JSON.parse(oesParameter).nativeOesBranding;
    }

    if (branding) {
      formPost.action = `${url}?appid=${activityId}&Branding=${branding}`;
    } else {
      formPost.action = `${url}?appid=${activityId}`;
    }

    formPost.method = 'post';
    formPost.target = '_blank';

    const tokenInput = document.createElement('input');
    tokenInput.type = 'hidden';
    tokenInput.name = 'Token';
    tokenInput.value = accessToken;

    formPost.appendChild(tokenInput)
    document.body.appendChild(formPost);
    formPost.submit();
    document.body.removeChild(formPost);
    return of(true);

    // const params = new HttpParams().set('appid', activityId);
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // const body = 'Token=' + encodeURIComponent(accessToken);
    // return this.http.post<any>(this.ANNUITY_FIRELIGHT_NATIVE_BUY, body , {params, headers, observe: 'response'});
  }

  getFIGBuy(annuityUserInput: AnnuityUserInputModel): Observable<FIGResponse> {
    return this.http.post<any>(this.ANNUITY_FIG_BUY, annuityUserInput);
  }

  launchFIGBuy(res: FIGResponse) {
    const headers = new HttpHeaders({authorization: 'Bearer ' + res?.accessToken});
    // const endpoint = environment.environmentName?.toLowerCase() !== 'prod' ? 'https://qa.partner.figdevteam.com/api/advisors/v1/advisors/af7cae6f-f470-4541-8069-f04c8ff08509/firelight/initiate?advisorIdType=partner' : res.endpoint;
    return this.http.post(res?.endpoint, res?.payload, {headers, responseType: 'text' as const});
  }


  getIncomeComparisonProducts(requestBody: IncomeComparisonRequestPayload): Observable<
    IncomeComparisonResponse
  > {
    return this.http.post<IncomeComparisonResponse>(this.ANNUITY_INCOME_COMPARISON, requestBody);
    // return of(MOCK_INCOME_PAYLOAD as any);
  }

  incomeComparisonExport(payload) {
    return this.http.post(this.ANNUITY_INCOME_COMPARISON_EXPORT, payload, {responseType: 'blob'});
  }

  getCarriersExcludedFromCopyDifferent() {
    return this.http.get<string[]>(this.ANNUITY_CARRIERS_COPY_DIFFERENT_DISABLED);
  }

  getTisTickers(): Observable<TISTickerObj[]> {
    return this.http.get<TISTickerObj[]>(`${this.ANNUITY_SERVICE}/tis/tickerresources`);
  }

  getTISProductResources({
                           state,
                           age,
                           productTypes,
                           brokerDealer
                         }: {
    state: string,
    age: number,
    productTypes: string[],
    brokerDealer?: any
  }): Observable<TISProductResource[]> {
    console.log('productutypes', productTypes);
    console.log('brok', brokerDealer);
    let params = new HttpParams();
    params = params.append('state', state);
    params = params.append('age', age.toString());
    params = params.append('pt', productTypes.join(','));
    if (brokerDealer) {
      params = params.append('bd', brokerDealer?.shortname);
    }
    return this.http.get<TISProductResource[]>(`${this.ANNUITY_SERVICE}/tis/productresources`, {params});
  }

}

export interface TISTickerObj {
  ticker: string;
  name: string;
  ratingLink: string;
  forecastLink: string;
  ranking: 'Platinum' | 'Gold' | 'Silver' | 'Copper' | 'Watch' | 'Neutral';
}

export interface TISProductResource {
  name: string;
  vuid: string;
  productProfile: string;
  resourceLink: string;
}

export interface EbixBuyResponse {
  responseBody: string;
  samlUrl: string;
}

export interface AnnuityBrokerDealerConfig {
  buyButtonMsg?: string;
  alwaysShowMsg?: boolean;
  buySupportMsg?: string;
}

export interface IncomeComparisonRequestPayload {
  stateCd: string; // ex: "OH",
  // contractCd: string; // ex: "S", derived from the backend
  premium: number; // ex: 100000,
  purchaseDate: string; // ex: "2023-08-29T00:00:00.000Z",
  genderPrimary: string; // ex: "M",
  purchaseAgePrimary: number; // ex: 60,
  incomeStartAgePrimary: number; // ex: 70,
  genderJoint: string; // ex: "F",
  purchaseAgeJoint: number; // ex: 57,
  incomeStartAgeJoint: number; // ex: 67,
  // vendorUniqueIds: string[]; // ex: ["0002322630-03284586], derived from the backend
  productTypes: string[]; // ex: ["FIA"]
  forecastType: Scenario;
}


export const ZERO_SCENARIO = 'ZERO';
export const MODERATE_SCENARIO = 'MODERATE';
export type Scenario = 'ZERO' | 'MODERATE';
export const SCENARIO_LABELS = {
  [ZERO_SCENARIO]: '0%',
  [MODERATE_SCENARIO]: 'Moderate Forecast'
};
export const AN_SCENARIOS: { label: string, value: Scenario }[] = [
  {
    label: SCENARIO_LABELS[ZERO_SCENARIO],
    value: ZERO_SCENARIO
  },
  {
    label: SCENARIO_LABELS[MODERATE_SCENARIO],
    value: MODERATE_SCENARIO
  }
];

export const MODERATE_SCENARIO_DESCRIPTION = `·    This is a hypothetical scenario based on the forecasted performance of an underlying index (e.g., S&P 500). A Monte Carlo simulation (using the GBM model) is used to create 1,000 random performance scenarios based on past index information. The Moderate Forecast is based on the 50th percentile of the average return and volatility.
·    The current cap & participation rates active within the associated crediting strategy are applied against the moderate forecast of the underlying index within the product.`;
export const ZERO_SCENARIO_DESCRIPTION = `This is a hypothetical market scenario where the index returns are equal to 0% in every crediting term. This scenario is intended to align with the Guaranteed scenario that is typically presented in an indexed-linked annuity illustration`;

export interface FiaRilaIncomeData {

  acctType: 'Brokerage' | 'Advisory' | string;
  mva: boolean;
  rop: boolean;
  surrenderPeriod: number;
  surrenderSchedule: string;
  freeWithdrawal: number | null;

  name: string; // ex: "American Legend 7",
  productType: string; // ex: "FIA",
  vendorUniqueId: string; // ex: "0002243531-00308948",
  cusip: string; // ex: "38983T628",
  carrier: string; // ex: "MassMutual Ascend",
  incomeData:
    {
      errorMsg?: string; // exists when error
      analyticalId: string;
      initialIncome: number; // ex:  8617.57,
      highestIncome: number; // ex:  8617.57,
      lowestIncome: number; // ex:  8617.57,
      scenario: Scenario;
      incomeRider: string;
    };
}

export interface FiaRilaIncomeDataGridScenarioData {
  analyticalId: string;
  initialIncome: number; // ex:  8617.57,
  highestIncome: number; // ex:  8617.57,
  lowestIncome: number; // ex:  8617.57,
  scenario: Scenario;
  incomeRider: string;
}

export interface AnnuityCompany {
  id: number,
  fullname: string,
  shortname: string,
  timezone: string,
  role: string,
}

export interface FiaRilaIncomeDataGridModel extends Omit<FiaRilaIncomeData, 'incomeData'> {
  incomeData: {
    scenario1?: FiaRilaIncomeDataGridScenarioData
    scenario2?: FiaRilaIncomeDataGridScenarioData
  };
}

interface IncomeComparisonBase {
  state: string; // ex: "OH",
  genderPrimary: string; // ex: "M",
  genderJoint: string; // ex: "F",
  premium: number; // ex: 100000,
  purchaseAgePrimary: number; // ex: 60,
  purchaseAgeJoint: number; // ex: 57,
  startAgePrimary: number; // ex: 70,
  startAgeJoint: number; // ex: 67,
}


export interface IncomeComparisonResponse extends IncomeComparisonBase {
  incomes: FiaRilaIncomeData[];
}

export const PAYMENT_FREQUENCIES_CONSTANTS = {
  MONTHLY: 'M',
  QUARTERLY: 'Q',
  SEMI_ANNUAL: 'SA',
  ANNUAL: 'A',
};

export const MONTHLY = 'Monthly';
export const QUARTERLY = 'Quarterly';
export const SEMI_ANNUAL = 'Semi-Annual';
export const ANNUAL = 'Annual';

export const PAYMENT_FREQUENCY_MAP = {
  [PAYMENT_FREQUENCIES_CONSTANTS.MONTHLY]: MONTHLY,
  [PAYMENT_FREQUENCIES_CONSTANTS.QUARTERLY]: QUARTERLY,
  [PAYMENT_FREQUENCIES_CONSTANTS.SEMI_ANNUAL]: SEMI_ANNUAL,
  [PAYMENT_FREQUENCIES_CONSTANTS.ANNUAL]: ANNUAL,
};
export const PAYMENT_FREQUENCIES_OPTIONS = [
  {
    value: PAYMENT_FREQUENCIES_CONSTANTS.MONTHLY,
    name: MONTHLY
  },
  {
    value: PAYMENT_FREQUENCIES_CONSTANTS.QUARTERLY,
    name: QUARTERLY
  },
  {
    value: PAYMENT_FREQUENCIES_CONSTANTS.SEMI_ANNUAL,
    name: SEMI_ANNUAL
  },
  {
    value: PAYMENT_FREQUENCIES_CONSTANTS.ANNUAL,
    name: ANNUAL
  },
];

export interface FIGResponse {
  endpoint: string; // "https://qa.partner.figdevteam.com/api/advisors/v1/advisors/7999656/firelight/initiate?advisorIdType=npn",
  accessToken: string;
  expireIn: number; // 3600,
  payload: {
    activityName: string; // "FIG-Application-null13:32:48.533015Z",
    acordTypeCode: string; // "103",
    dataItems: { dataItemId: string, value: string }[]
  };
}
